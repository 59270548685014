import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout
    sermonSrc="https://www.youtube.com/embed/OslyVLxYzrw"
    bibleGroupSrc="https://www.docdroid.net/LW05kFs/bible-group-homework-6-14-pdf"
    bibleGroupText="Bible Group Homework"
    notesText="Sermon Notes"
    notesSrc="http://bible.com/events/18969721"
  >
    <SEO title="Why Churches Suck - Colossians" />
  </Layout>
)

export default SermonPost
